import React, { useState, useCallback } from "react";
import CustomModal from "./lib/CustomModal";
import { format } from "date-fns";

const CURRENT_DATE_KEY = `shopHolidayReminder:${format(new Date, 'yyyymm')}`;
const CHECKED = 'checked';

const ShopHolidayReminder: React.FC = () => {
  const [isOpen, setIsOpen] = useState(localStorage.getItem(CURRENT_DATE_KEY) !== CHECKED);
  const [isChecked, setIsChecked] = useState(false);

  const close = useCallback(() => {
    if (isChecked) offRemindThisMonth();
    setIsOpen(false);
  }, [isChecked]);

  const offRemindThisMonth = () => {
    localStorage.setItem(CURRENT_DATE_KEY, CHECKED);
  };

  return <CustomModal
    isOpen={isOpen}
    onRequestClose={close}
  >
    <div className="center-align">
      <h5>今月の休業日は設定されましたか？</h5>
      <label>
        <input
          type="checkbox"
          className="filled-in"
          checked={isChecked}
          onChange={(e) => {
            setIsChecked((e.target as HTMLInputElement).checked);
          }}
        />
        <span className="black-text mt-5">今月は表示しない</span>
      </label>
    </div>

    <div className="row mt-5">
      <div className="col s5 offset-s2">
        <a className="waves-effect waves-light btn" onClick={() => {
          offRemindThisMonth();
          location.href = '/calendar';
        }}
        >
          休業日設定へ
        </a>
      </div>
      <div className="col s5">
        <a className="waves-effect waves-light btn grey" onClick={close}>閉じる</a>
      </div>
    </div>
  </CustomModal>;
};

export default ShopHolidayReminder;
