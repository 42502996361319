import React, { useState } from 'react';

type Props = {
  stock: number
}
const StockChangeInput = ({ stock }: Props) => {
  const [count, setCount] = useState<number>(stock);

  const handleIncrement = () => {
    if (count < 99) {
      setCount(count + 1);
    }
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 99) {
      setCount(newValue);
    }
  };

  return (
    <div className="valign-wrapper">
      <button type="button" className="btn-floating btn-small waves-effect waves-light tabete-color" onClick={handleDecrement}><i
        className="material-icons">remove</i></button>
      <div style={{width: '2.5rem'}}>
        <input
          type="text"
          value={count}
          onChange={handleChange}
          name="stock"
          className='center-align'
        />
      </div>
      <button type="button" className="btn-floating btn-small waves-effect waves-light tabete-color" onClick={handleIncrement}><i
        className="material-icons">add</i></button>
    </div>
  );
};

export default StockChangeInput;
