import React, { useState } from "react";
import CustomModal from "./lib/CustomModal";
import Axios from "axios";

type Props = {
  orderDate: string
  orderUuid: string
  shopName: string
  orderQuantity: string
  totalOrder: string
  cancelReason: string
  path: string
}

const RefundRequestModalAdmin = ({ orderDate, orderUuid, shopName, orderQuantity, totalOrder, cancelReason, path }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handlePost = async () => {
    const postData = {
      order_uuid: orderUuid,
    };

    const response = await Axios.post(path, postData);
    window.location.href = `/cancel_requests?is_cancel=${response.data.is_cancel}&is_approved=${response.data.is_approved}`;
  };
  return (
    <>
      <button className="btn" onClick={handleOpenModal}>返金承認</button>
      <CustomModal isOpen={isOpen} onRequestClose={handleCloseModal} ariaHideApp={false}>
        <div className="center-align row">
          <h5 className="bold">この注文の返金依頼を差し戻します</h5>
          <div className="col s4 offset-s4 left-align">
            <h6>購入日時：<span className="bold">{orderDate}</span></h6>
            <h6>注文番号：<span className="bold">{orderUuid}</span></h6>
            <h6>店舗名：<span className="bold">{shopName}</span></h6>
            <h6>注文個数：<span className="bold">{orderQuantity}個</span></h6>
            <h6>合計金額：<span className="bold">{totalOrder}</span></h6>
            <h6>返金理由</h6>
            <pre>{cancelReason}</pre>
            <div className="mt-10">
              <button className="btn btn-stop black-text" onClick={handleCloseModal}>キャンセル</button>
              <button onClick={handlePost} className="btn ml-10">返金確定</button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default RefundRequestModalAdmin;
