import React, { useState } from "react";
import { RadioGroup, TextInput } from "react-materialize";
import Axios from 'axios';

type Props = {
  path: string
  defaultCondifionQuery?: string
  defaultFetchModel?: string
  fetchModelKeyValue: { [key: string]: string }
}

const SendEmailsInput = ({ defaultCondifionQuery, defaultFetchModel, path, fetchModelKeyValue }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [inputFetchModel, setInputFetchModel] = useState(defaultFetchModel ?? 'no_condition');
  const [count, setCount] = useState();
  const [query, setQuery] = useState(defaultCondifionQuery ?? `{
  email_optin: true,
  state: :active,
  line1_id: [1, 3],
  id: 1000...
}`);

  const isfetchModel = inputFetchModel !== 'no_condition' && inputFetchModel !== 'text_file';
  const fetchEmail = async () => {
    try {
      const { data } = await Axios.get(path, {
        params: { query: encodeURI(query), fetch_model: inputFetchModel }
      });
      setInputValue(data.emails.join('\n'));
      setCount(data.count);
    } catch (error) {
      alert('絞り込みに失敗しました。条件を確認してください。');
    }
  };

  return (
    <>
      <RadioGroup
        name="sent_email_history[fetch_model]"
        onChange={(e) => {
          // TODO: radio の e 型付け
          // @ts-ignore
          const vaule = e.target.defaultValue;
          isfetchModel ? setInputValue('') : setCount(undefined)
          setInputFetchModel(vaule);
        }}
        value={inputFetchModel}
        options={Object.entries(fetchModelKeyValue).map(([value, label]) => { return { value, label }; })}
      />
      {
        isfetchModel && (
          <>
            <textarea name="sent_email_history[condition_query]" rows={6} className="tbt-height-initial" onChange={(e) => setQuery(e.target.value)} value={query} />
            <button className="btn" type="button" onClick={fetchEmail}>宛先リスト取得</button>
          </>
        )
      }
      {count != null && <span className="ml-2">{count}件</span>}
      {
        isfetchModel && (
          <>
            <p>絞り込み結果 下位30件</p>
            <textarea rows={6} className="tbt-height-initial" disabled value={inputValue} />
          </>
        )
      }
      {
        inputFetchModel === 'no_condition' && (
          <div className="mt-10">
            <TextInput
              label="宛先(メールアドレスカンマ or スペース区切り)"
              name="sent_email_history[sent_emails]"
              noLayout
              value={inputValue}
              onChange={(e) => {
                setInputFetchModel('no_condition');
                setCount(undefined);
                setInputValue(e.target.value);
              }}
            />
          </div>
        )
      }
      {
        inputFetchModel === 'text_file' && (
          <p><input type="file" name="sent_email_history[text_file]" id="sent_email_history_text_file" /></p>
        )
      }
    </>
  );
};

export default SendEmailsInput;
