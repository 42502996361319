import React, { FormEvent, useState } from "react";
import CustomModal from "./lib/CustomModal";
import { TextInput, Select } from "react-materialize";
import Axios from "axios";

type Option = {
  value: string;
  label: string;
}

type Props = {
  orderDate: string
  orderUuid: string
  shopName: string
  orderQuantity: string
  totalOrder: string
  cancelReason: string
  path: string
  productList: Option[]
}

const PartialRefundRequestModalAdmin = ({ orderDate, orderUuid, shopName, orderQuantity, totalOrder, cancelReason, path, productList }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const response = await Axios.post(path, { order_id: event.currentTarget.orderId.value, num: event.currentTarget.num.value });
    window.location.href = `/cancel_requests?greater_than_order_num=${response.data.greater_than_order_num}&has_partial_refund=${response.data.has_partial_refund}`;
  };

  return (
    <>
      <button className="btn white black-text ml-1" onClick={handleOpenModal}>一部返金</button>
      <CustomModal isOpen={isOpen} onRequestClose={handleCloseModal} ariaHideApp={false}>
        <div className="center-align row">
          <h5 className="bold">この注文の注文を一部返金します</h5>
          <div className="col s4 offset-s4 left-align mb-4">
            <h6>購入日時：<span className="bold">{orderDate}</span></h6>
            <h6>注文番号：<span className="bold">{orderUuid}</span></h6>
            <h6>店舗名：<span className="bold">{shopName}</span></h6>
            <h6>注文個数：<span className="bold">{orderQuantity}個</span></h6>
            <h6>合計金額：<span className="bold">{totalOrder}</span></h6>
            <h6>返金理由</h6>
            <pre>{cancelReason}</pre>
          </div>
        </div>
        <div className="center-align row mb-0">
          <form onSubmit={handleSubmit}>
            <div className="row mb-0">
              <div className="col offset-s2 s8">
                <Select
                  noLayout
                  name="orderId"
                >
                  <option
                    hidden
                    value=""
                  >
                    キャンセルする商品を選択
                  </option>
                  {
                    productList.map(product => {
                      return <option key={product.value} value={product.value}>
                        {product.label}
                      </option>;
                    })
                  }
                </Select>
              </div>
            </div>
            <div className="row">
              <div className="col offset-s3 s6">
                <TextInput
                  type="number"
                  name="num"
                  label="キャンセルする個数"
                  noLayout
                />
              </div>
            </div>
            <div>
              <button className="btn btn-stop black-text" onClick={handleCloseModal}>キャンセル</button>
              <button type="submit" className="btn ml-10">確定</button>
            </div>
          </form>
        </div>
      </CustomModal>
    </>
  );
}

export default PartialRefundRequestModalAdmin;
