import React from 'react';
import Modal from 'react-modal';

const CustomModal: React.FC<Modal.Props> = (props) => {
  return (
    <Modal
      style={{ overlay: { zIndex: 1000 }, content: { margin: 'auto', width: '50%', top: '50%', bottom: 'unset', left: '50%', right: 'unset', transform: 'translate(-50%, -50%)'} }}
      {...props}
    >
      {props.children}
    </Modal>
  );
}; 

export default CustomModal;
