import React, { useMemo, useState } from "react";
import classnames from 'classnames';
import { Textarea, TextInput } from "react-materialize";

type Props = {
  mode: 'textarea' | 'textimput'
  inputName: string
  placeholder?: string,
  maxLenght: number,
  inputId: string,
  defaultValue?: string
  labelValue?: string
  required?: boolean
  readonly?: boolean
}

const StringCountableInput = ({ inputName, placeholder, maxLenght, inputId, defaultValue, labelValue, mode, required, readonly }: Props) => {
  const [value, setValue] = useState(defaultValue ?? '');
  const Compornent = useMemo(() => {
    switch (mode) {
      case 'textarea':
        return Textarea;
      case 'textimput':
        return TextInput;
    }
  }, [mode]);
  return <div className="tbt-react-materialize-input">
    <Compornent
      value={value}
      onChange={(e) => setValue(e.target.value.replace(/\n/g, "\r\n"))}
      name={inputName}
      label={labelValue}
      placeholder={placeholder}
      id={inputId}
      noLayout
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // なぜか required のプロパティが存在しないのでしかたなく ignore
      required={required}
      readOnly={readonly}
    />
    <p className={classnames(['limit-text', { 'red-text': value.length > maxLenght }])}>
      {value.length} / {maxLenght}字
    </p>
  </div>;
};

export default StringCountableInput;
