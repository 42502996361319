import React, { useState } from "react";

type Props = {
  labelText: string
  inputName: string
  defaultInput: string
  checkboxLabel?: string
}

const ToggleDateTimeInput = ({ labelText, inputName, defaultInput, checkboxLabel, }: Props) => {
  const [isChecked, setIsChecked] = useState(defaultInput != null);
  const [dateTime, setDateTime] = useState(defaultInput);

  return (
    <>
      <label>
        {labelText}
      </label>
      <div className="col s12 mt-8">
        <label>
          <input
            type="checkbox"
            className="filled-in"
            checked={isChecked}
            onChange={(e) => {
              const { checked } = e.target as HTMLInputElement;
              setIsChecked(checked);
            }}
          />
          <span className="black-text">{checkboxLabel}</span>
        </label>
      </div>
      {isChecked && (
        <div className="row col s12">
          <input
            type="datetime-local"
            value={dateTime}
            onChange={(event) => {
              setDateTime(event.target.value)
            }}
            name={inputName}
            required
            onInvalid={(event) => {
              (event.target as HTMLInputElement).setCustomValidity('終了時間を正しく設定してください');
            }}
          />
        </div>
      )}
    </>
  );
};

export default ToggleDateTimeInput;
