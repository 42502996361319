import React, { FormEvent, useState } from "react";
import CustomModal from "./lib/CustomModal";
import Axios from 'axios';
import { Textarea } from "react-materialize";

type Props = {
  orderDate: string
  orderUuid: string
  orderQuantity: string
  totalOrder: string
  path: string
  cashId: string
}

const RefundRequestModal = ({ orderDate, orderUuid, orderQuantity, totalOrder, path, cashId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await Axios.post(path, { cash_id: cashId, cancel_reason: event.currentTarget.cancel_reason.value });
    window.location.href = `/products/cancel_requests?is_success=true`;
  };
  return (
    <>
      <button className="btn" onClick={handleOpenModal}>返金依頼</button>
      <CustomModal isOpen={isOpen} onRequestClose={handleCloseModal} ariaHideApp={false}>
        <div className="center-align row">
          <h5 className="bold">この注文の返金依頼を行います</h5>
          <div className="col s4 offset-s4 left-align">
            <h6>購入日時：<span className="bold">{orderDate}</span></h6>
            <h6>注文番号：<span className="bold">{orderUuid}</span></h6>
            <h6>注文個数：<span className="bold">{orderQuantity}個</span></h6>
            <h6>合計金額：<span className="bold">{totalOrder}</span></h6>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col s8 offset-s2 mt-5">
                <Textarea
                  label="返金理由・備考（必須）"
                  // なぜかプロパティが無いのでエスケープ
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  name="cancel_reason"
                  required
                  noLayout
                />
              </div>
            </div>
            <button className="btn btn-stop black-text" onClick={handleCloseModal}>キャンセル</button>
            <button type="submit" className="btn ml-10">返金を依頼</button>
          </form>
        </div>
      </CustomModal>
    </>
  );
}

export default RefundRequestModal;
