import React, { useState } from "react";
import { TextInput } from "react-materialize";

type Props = {
  inputName: string
  inputLabel: string
  defaultInput?: string
  checkboxLabel: string
}

const ToggleInput = ({ inputName, checkboxLabel, inputLabel, defaultInput }: Props) => {
  const [isChecked, setIsChecked] = useState(defaultInput != null && defaultInput !== '');
  const [inputValue, setInputValue] = useState(defaultInput || '');

  return (
    <>
      <div className="col s12 mt-8">
        <label>
          <input
            type="checkbox"
            className="filled-in"
            checked={isChecked}
            onChange={(e) => {
              setIsChecked((e.target as HTMLInputElement).checked);
            }}
          />
          <span className="black-text">{checkboxLabel}</span>
        </label>
      </div>
      <TextInput
        label={inputLabel}
        name={inputName}
        disabled={!isChecked}
        defaultValue={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        s={12}
      />
    </>
  );
};

export default ToggleInput;
