import React, { useState } from "react";
import CustomModal from "./lib/CustomModal";
import Axios from "axios";

type Props = {
  orderDate: string
  orderUuid: string
  shopName: string
  orderQuantity: string
  totalOrder: string
  path: string
}

const RefundRequestRejectModalAdmin = ({ orderDate, orderUuid, shopName, orderQuantity, totalOrder, path }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  }

  const handlePost = async () => {
    const response = await Axios.post(path);
    window.location.href = `/cancel_requests?is_rejected=${response.data.is_rejected}`;
  };
  return (
    <>
      <button className="btn red ml-1" onClick={handleOpenModal}>差し戻し</button>
      <CustomModal isOpen={isOpen} onRequestClose={handleCloseModal} ariaHideApp={false}>
        <div className="center-align row">
          <h5 className="bold">この注文の返金依頼を差し戻します</h5>
          <div className="col s4 offset-s4 left-align">
            <h6>購入日時：<span className="bold">{orderDate}</span></h6>
            <h6>注文番号：<span className="bold">{orderUuid}</span></h6>
            <h6>店舗名：<span className="bold">{shopName}</span></h6>
            <h6>注文個数：<span className="bold">{orderQuantity}個</span></h6>
            <h6>合計金額：<span className="bold">{totalOrder}</span></h6>
            <button className="btn btn-stop black-text mt-10" onClick={handleCloseModal}>キャンセル</button>
            <button onClick={handlePost} className="btn mt-10 ml-10">差し戻し</button>
          </div>

        </div>
      </CustomModal>
    </>
  );
}

export default RefundRequestRejectModalAdmin;
