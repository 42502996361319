import React, { useMemo } from "react";

type Props = {
  colorClass: string
  size: string
}

const Circle = ({ colorClass, size }: Props) => {
  const style = useMemo(() => {
    return {
      width: size,
      height: size,
      lineHeight: size,
      borderRadius: '50%',
      color: '#fff',
      alignText: 'center',
    };
  }, [size]);
  return <div className={colorClass} style={style} />;
};

export default Circle;
