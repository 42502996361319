import React, { useCallback, useMemo, useState } from "react";
import useSWR from 'swr';
import Axios from 'axios';
import allLocales from '@fullcalendar/core/locales-all';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { format, isToday } from "date-fns";
import classnames from 'classnames';
import ja from 'date-fns/locale/ja';
import { DatesSetArg } from "@fullcalendar/core";
import Circle from "./Circle";
import CustomModal from "./lib/CustomModal";

const holidaysAndReservationDaysFetcher = async (url: string, start: string, end: string) => {
  const { data } = await Axios.get(`${url}?start=${start}&end=${end}`);
  return data;
};

const productReservationsByDayFetcher = async (url: string, day: string) => {
  const { data } = await Axios.get(`${url}?day=${day}`);
  return data;
};

const ModalDate = ({ date, isHolidayJapan }: { date: Date, isHolidayJapan: boolean }) => {
  return <h5>{format(date, 'yyyy年MM月dd日', { locale: ja })}({format(date, 'eee', { locale: ja })}{isHolidayJapan && '・祝'})</h5>;
};

const ReservationModal: React.FC<{ openingDateArg: DateClickArg, closeModal: () => void, createShopHoliday: (holiday: string) => void, isHolidayJapan: boolean }> = ({ openingDateArg, closeModal, createShopHoliday, isHolidayJapan }) => {
  const { data: productReservationsByDay } = useSWR(openingDateArg ? ['/calendar/product_reservations_by_day', openingDateArg.dateStr] : null, ([url, day]) => productReservationsByDayFetcher(url, day));
  return (
    <div className="center-align">
      <ModalDate date={openingDateArg.date} isHolidayJapan={isHolidayJapan} />
      <div className="row valign-wrapper">
        <div className="row valign-wrapper mb-0">
          <Circle colorClass="green" size="30px" />
          <h5 className="ml-4">通常通りの出品あり</h5>
        </div>
      </div>
      {isToday(openingDateArg.date) && <p>当日の出品変更は<a href="/dashboards" className="tbt-reset-a">トップページ</a>より行ってください</p>}
      {
        productReservationsByDay && (
          productReservationsByDay.map(pr => (
            <div className="row mt-3 valign-wrapper" key={pr.id}>
              <div className="col s1"><img src={pr.product.top_img_url} width="50px" height="50px" /></div>
              <div className="col s6">{pr.product.name}</div>
              <div className="col s2">{format(new Date(pr.start_time), 'HH:mm')} 掲載</div>
              <div className="col s1">{pr.stock}個</div>
            </div>
          )
          ))
      }
      <div className="row mt-3">
        <div className={classnames({ 'col s5 offset-s1': openingDateArg.date > new Date })}>
          <a className="waves-effect waves-light btn" onClick={closeModal}>閉じる</a>
        </div>

        {openingDateArg.date > new Date && (
          <div className="col s5">
            <a className="waves-effect waves-light btn red darken-4" onClick={() => createShopHoliday(openingDateArg.dateStr)}>この日を休業日にする</a>
          </div>
        )}
      </div>
    </div>
  );
};

const ShopHolidayModal: React.FC<{ openingDateArg: DateClickArg, closeModal: () => void, deleteShopHoliday: (shopHoliday: string) => void, isHolidayJapan: boolean }> = ({ openingDateArg, closeModal, deleteShopHoliday, isHolidayJapan }) => {
  return (
    <div className="center-align">
      <ModalDate date={openingDateArg.date} isHolidayJapan={isHolidayJapan} />
      <div className="row valign-wrapper">
        <div className="row valign-wrapper">
          <Circle colorClass="red" size="30px" />
          <h5 className="ml-4">休業日に設定されています</h5>
        </div>
      </div>
      <div className="row mt-3">
        <div className={classnames({ 'col s5 offset-s1': openingDateArg.date > new Date })}>
          <a className="waves-effect waves-light btn" onClick={closeModal}>閉じる</a>
        </div>
        {openingDateArg.date > new Date && (
          <div className="col s5">
            <a className="waves-effect waves-light btn red darken-4" onClick={() => deleteShopHoliday(openingDateArg.dateStr)}>休業日を削除</a>
          </div>
        )}
      </div>
    </div>
  );
};

const NoSettingsModal: React.FC<{ openingDateArg: DateClickArg, closeModal: () => void, createShopHoliday: (shopHoliday: string) => void, isHolidayJapan: boolean }> = ({ openingDateArg, closeModal, createShopHoliday, isHolidayJapan }) => {
  return (
    <div className="center-align">
      <ModalDate date={openingDateArg.date} isHolidayJapan={isHolidayJapan} />
      <h5>この日にはスケジュール出品が設定れていません</h5>
      <div className="row mt-3">
        <div className={classnames({ 'col s5 offset-s1': openingDateArg.date > new Date })}>
          <a className="waves-effect waves-light btn" onClick={closeModal}>閉じる</a>
        </div>
        {openingDateArg.date > new Date && (
          <div className="col s5">
            <a className="waves-effect waves-light btn red darken-4" onClick={() => createShopHoliday(openingDateArg.dateStr)}>この日を休業日にする</a>
          </div>
        )}
      </div>
    </div>
  );
};

const Calendar = () => {
  // [startStr, endStr]
  const [dayRange, setDayRange] = useState<[string, string]>();
  const [openingDateArg, setOpeningDateArg] = useState<DateClickArg>();
  const handleDatesChange = (arg: DatesSetArg) => {
    setDayRange([arg.startStr, arg.endStr]);
  };
  const { data: holidaysAndReservationDays, mutate: holidaysAndReservationDaysMutate } = useSWR(dayRange ? ['/calendar/holidays_and_reservation_days', dayRange[0], dayRange[1]] : null, ([url, start, end]) => holidaysAndReservationDaysFetcher(url, start, end));

  const events = useMemo(() => {
    const holidayEvents = holidaysAndReservationDays?.shop_holidays?.map(sh => { return { title: '店舗休業日（出品なし）', start: sh, classNames: ['red', 'tbt-pointer-events-none'] }; }) ?? [];
    const reservationEvents = holidaysAndReservationDays?.reservation_days?.map(rd => { return { title: '出品あり', start: rd, classNames: ['green', 'tbt-pointer-events-none'] }; }) ?? [];
    const holidayJapanEvents = holidaysAndReservationDays?.holiday_japan_list?.map(hj => { return { title: '祝日', start: hj, classNames: ['red lighten-3', 'tbt-pointer-events-none'] }; }) ?? [];

    return [
      ...holidayEvents,
      ...reservationEvents,
      ...holidayJapanEvents
    ];
  }, [holidaysAndReservationDays]);

  const modalState = (dateStr: string): '店舗休業日（出品なし）' | '出品あり' | '設定なし' => {
    const targets = events.filter(e => e.start === dateStr);
    if (targets.length === 0) return '設定なし';
    const titles = targets.map(t => t.title);
    if (titles.includes('店舗休業日（出品なし）')) return '店舗休業日（出品なし）';
    if (titles.includes('出品あり')) return '出品あり';
    throw Error('不明なイベントが設定されています');
  };

  const checkHolidayJapan = (dateStr: string): boolean => {
    const targets = events.filter(e => e.start === dateStr);
    return targets?.map(t => t.title)?.includes('祝日');
  };

  const createShopHoliday = useCallback(async (holiday: string) => {
    await Axios.post('/calendar/create_shop_holiday', { holiday });
    await holidaysAndReservationDaysMutate();
    setOpeningDateArg(undefined);
  }, [openingDateArg]);

  const deleteShopHoliday = useCallback(async (holiday: string) => {
    await Axios.delete(`/calendar/destroy_shop_holiday?holiday=${holiday}`);
    await holidaysAndReservationDaysMutate();
    setOpeningDateArg(undefined);
  }, [openingDateArg]);

  return (
    <>
      {openingDateArg !== undefined && (
        <CustomModal
          isOpen={openingDateArg !== undefined}
          onRequestClose={() => setOpeningDateArg(undefined)}
        >
          {modalState(openingDateArg.dateStr) === '店舗休業日（出品なし）' && (
            <ShopHolidayModal openingDateArg={openingDateArg} closeModal={() => setOpeningDateArg(undefined)} deleteShopHoliday={deleteShopHoliday} isHolidayJapan={checkHolidayJapan(openingDateArg.dateStr)} />
          )}
          {modalState(openingDateArg.dateStr) === '出品あり' && (
            <ReservationModal openingDateArg={openingDateArg} closeModal={() => setOpeningDateArg(undefined)} createShopHoliday={createShopHoliday} isHolidayJapan={checkHolidayJapan(openingDateArg.dateStr)} />
          )}
          {modalState(openingDateArg.dateStr) === '設定なし' && (
            <NoSettingsModal openingDateArg={openingDateArg} closeModal={() => setOpeningDateArg(undefined)} createShopHoliday={createShopHoliday} isHolidayJapan={checkHolidayJapan(openingDateArg.dateStr)} />
          )}

        </CustomModal>
      )}
      <FullCalendar
        locales={allLocales}
        locale="ja"
        plugins={[dayGridPlugin, interactionPlugin]}
        events={events}
        datesSet={handleDatesChange}
        dateClick={(arg) => { setOpeningDateArg(arg); }}
        buttonText={{ today: '今月' }}
      />
    </>
  );
};


const CalendarPage = () => {
  return <Calendar />;
};
export default CalendarPage;
