import React, { useState } from "react";
import { Select } from "react-materialize";

type Option = {
  value: string;
  label: string;
}

type Props = {
  defaultCheckbox?: boolean
  checkboxLabel: string
  checkboxName: string
  selectName: string
  defaultSelect?: string
  objectList: Option[]
}

const ToggleSelect = ({ defaultCheckbox, checkboxLabel, checkboxName, selectName, defaultSelect, objectList }: Props) => {
  const [isChecked, setIsChecked] = useState(defaultCheckbox);

  return (
    <>
      <div className="col s12">
        <label>
          <input
            type="checkbox"
            name={checkboxName}
            className="filled-in"
            checked={isChecked}
            onChange={(e) => {
              setIsChecked((e.target as HTMLInputElement).checked);
            }}
          />
          <span className="black-text">{checkboxLabel}</span>
        </label>
      </div>
      <Select
        disabled={!isChecked}
        name={selectName}
        value={defaultSelect}
      >
        <option hidden value=''>選択してください</option>
        {
          objectList.map(locker => {
            return <option key={locker.value} value={locker.value}>
              {locker.label}
            </option>;
          })
        }
      </Select>
    </>
  );
};

export default ToggleSelect;
